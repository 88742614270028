import React from "react"
import Layout from "../components/other/layout"

export default () => {
  return (
    <Layout>
      <div>Sorry the page you requested could not be found</div>
    </Layout>
  )
}
